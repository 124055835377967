'use client';

import React, { useState, useCallback } from 'react';
import {
  ListFilesResponseBody,
  SearchTagsRequestQuery,
  SearchTagsResponseBody,
  UpdateFileTagResponseBody,
} from 'bff';
import { Button, cn, Combobox } from 'ui';
import { IconPencil, IconTag } from '@tabler/icons-react';
import { SINGLE_FILE_QUERY_KEY } from './FileViewerContext';
import { useQueryClient } from '@tanstack/react-query';
import { useApiError } from '..';

type File = ListFilesResponseBody['files'][number];

interface FileTagEditorProps {
  file: File;
  updateFileTag?: (
    fileId: string,
    tagId: string,
    inferredFileDataId?: string,
  ) => Promise<UpdateFileTagResponseBody> | undefined;
  searchTags?: (
    query: SearchTagsRequestQuery,
    cookie?: string,
  ) => Promise<SearchTagsResponseBody>;
  userType?: string;
}

export const FileTagEditor: React.FC<FileTagEditorProps> = ({
  file,
  updateFileTag,
  searchTags,
  userType,
}) => {
  const queryClient = useQueryClient();
  const { handleError } = useApiError();

  const [isEditingTag, setIsEditingTag] = useState(false);

  const handleTagSelection = useCallback(
    (value: string, label: string) => {
      // @ts-ignore
      return updateFileTag(file.id, value, file.InferredFileData?.id)
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [SINGLE_FILE_QUERY_KEY, file.id],
          });
          setIsEditingTag(false);
        })
        .catch(handleError);
    },
    [file.id, updateFileTag, queryClient, handleError],
  );

  return (
    <div
      className='flex flex-col items-start justify-start animate-in fade-in duration-1000'
      onClick={() => {
        setIsEditingTag(!isEditingTag);
      }}
    >
      <div
        className={cn(
          'flex flex-row items-center justify-start space-x-1 group hover:cursor-pointer',
          {
            'hover:cursor-default':
              !updateFileTag ||
              userType === 'third' ||
              userType === 'inhouse_lawyer',
          },
        )}
      >
        <IconTag className='w-[0.875rem] h-[0.875rem] text-primary' />
        <p className='text-xs font-medium text-piramid-text p-0'>
          {file.tags.map((tag) => tag.name).join(', ')}
        </p>
        {}
        <Button
          variant='ghost'
          className={cn(
            'hover:bg-transparent p-0 group-hover:opacity-100 opacity-0',
            {
              'opacity-0 group-hover:opacity-0 cursor-default':
                !updateFileTag ||
                userType === 'third' ||
                userType === 'inhouse_lawyer',
            },
          )}
          onClick={() => {
            setIsEditingTag(!isEditingTag);
          }}
        >
          <IconPencil className='w-[.875rem] h-[.875rem] text-stone-300' />
        </Button>
      </div>
      {isEditingTag &&
        updateFileTag &&
        searchTags &&
        userType !== 'third' &&
        userType !== 'inhouse_lawyer' && (
          <div className='mb-3 w-full'>
            <Combobox
              key={file.id}
              className={cn(
                'max-w-full !text-base_brand text-stone-400 h-[1.5625rem] rounded-sm border border-stone-300',
              )}
              popoverClassName='border-stone-300'
              classNames={{
                placeholder:
                  'text-stone-400 text-xs font-medium text-[.6875rem]',
                searchInput: 'text-[.6875rem] h-[1.5625rem]',
                item: 'text-[.6875rem]',
                emptyState: 'text-[.6875rem] py-3',
              }}
              render={({ label }) => <>{label}</>}
              placeholder='Busca y selecciona una etiqueta'
              onSelect={({ value, label }) =>
                handleTagSelection(value.toString(), label)
              }
              defaultOptions={[]}
              emptyStateMessage='No se encontraron etiquetas'
              withEmptySearchTerm={true}
              withPrefetch={true}
              loadOptions={async (search) =>
                searchTags({ limit: 10, search }).then((res) =>
                  res.tags.map((t) => ({
                    label: t.name,
                    value: t.id.toString(),
                  })),
                )
              }
            />
          </div>
        )}
    </div>
  );
};
