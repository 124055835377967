'use client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AttachFilesRequestBody,
  FetchInsurerMinimalClaimDetailResponseBody,
  ListFilesRequestQuery,
  ListFilesResponseBody,
  OrderRelationTypeMetadata,
  TokenizeFileRequestBody,
  TokenizeFileResponseBody,
} from 'bff';
import { UserType } from 'database';
import debounce from 'lodash.debounce';
import React, { useCallback, useState, useMemo } from 'react';
import {
  FileCardList,
  FileRequestMessage,
  SINGLE_FILE_QUERY_KEY,
} from 'shared-components';
import { Button, ComboboxOption, Input, cn } from 'ui';
import { filterDocumentsByVisibility } from 'utils';
import Link from 'next/link';
import { DocumentTagsFilter, SearchTagsFn } from './DocumentTagsFilter';
import {
  RetrievePendingFileRequestResponseBody,
  VisibilityCategories,
  FileRequestWithRelations,
} from 'bff';
import { UploadFileCustom } from './UploadFileCustom';
import { DocumentsEmptyState } from './DocumentsEmptyState';
import MemoizedFileCard from './MemoizedFileCard';

export const DOCUMENTATION_FILES_QUERY_KEY = 'documentation-files';

export const buildClaimPendingFileRequestQueryKey = (claimId: string) =>
  `claim-${claimId}-pending-file-request`;

export type ListFilesFN = (input: {
  query?: ListFilesRequestQuery;
  cookie?: string;
}) => Promise<ListFilesResponseBody>;

type File = ListFilesResponseBody['files'][number] & {
  _version?: number;
};

type ListPendingFileRequestFN = (
  params: OrderRelationTypeMetadata,
  cookie?: string,
) => Promise<RetrievePendingFileRequestResponseBody>;

export type DocumentsFileExplorerProps = {
  initialData: ListFilesResponseBody;
  searchTags?: SearchTagsFn;
  listFiles: ListFilesFN;
  listPendingFileRequest: ListPendingFileRequestFN;
  onFilterChange?: (filters: { selectedTags: ComboboxOption[] }) => void;
  onSearch?: (search: string) => void;
  onOpenFile?: (file: File) => void;
  withFileRequestButton?: boolean;
  title?: string;
  docQueryKey?: string;
  claim?: FetchInsurerMinimalClaimDetailResponseBody;
  // @ts-ignore
  initialPendingFileRequests?: RetrievePendingFileRequestResponseBody;
  noTagPlaceholder?: boolean;
  tokenizeFile?: (
    input: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  addUntaggedFiles: (files: AttachFilesRequestBody['files']) => Promise<any>;
  visibility?: Partial<VisibilityCategories>;
  withPlaceholderEmptyState?: boolean;
  userType?: UserType;
  initialGlobalFilter?: string;
  initialSelectedTags?: ComboboxOption[];
  hideSearch?: boolean;
} & Pick<ListFilesRequestQuery, 'scope_id' | 'scope_type'>;

export const DocumentsFileExplorer = ({
  initialData,
  scope_id,
  scope_type,
  searchTags = async () => ({ options: [], tags: [] }),
  listFiles,
  onFilterChange,
  onSearch,
  onOpenFile,
  withFileRequestButton = true,
  title,
  claim,
  docQueryKey,
  visibility = { tramitation: true, owner: true },
  initialPendingFileRequests,
  noTagPlaceholder = false,
  tokenizeFile,
  addUntaggedFiles,
  withPlaceholderEmptyState = true,
  userType,
  listPendingFileRequest,
  initialGlobalFilter,
  initialSelectedTags,
  hideSearch = false,
}: DocumentsFileExplorerProps) => {
  const [selectedTags, setSelectedTags] = useState<ComboboxOption[]>(
    initialSelectedTags || [],
  );
  const [globalFilter, setGlobalFilter] = useState(initialGlobalFilter || '');
  const [isRefetching, setIsRefetching] = useState(false);
  const queryClient = useQueryClient();

  const filesQuery = useQuery({
    queryKey: [
      DOCUMENTATION_FILES_QUERY_KEY,
      docQueryKey,
      scope_id,
      JSON.stringify(selectedTags.map((v) => v.value)),
      globalFilter,
      initialGlobalFilter,
      initialSelectedTags ? JSON.stringify(initialSelectedTags) : undefined,
    ] as const,
    queryFn: async () => {
      if (
        initialGlobalFilter !== undefined &&
        initialGlobalFilter !== globalFilter
      ) {
        setGlobalFilter(initialGlobalFilter);
      }

      if (
        initialSelectedTags !== undefined &&
        JSON.stringify(initialSelectedTags) !== JSON.stringify(selectedTags)
      ) {
        setSelectedTags(initialSelectedTags);
      }

      const response = await listFiles({
        query: {
          scope_id,
          scope_type,
          search: globalFilter,
          tags: selectedTags
            .map((v) => v.value)
            .filter((value): value is string => typeof value === 'string'),
          ownerId: claim?.owner?.id,
        },
      });

      return {
        files: filterDocumentsByVisibility({
          files: response.files,
          ownerId: claim?.owner?.id,
          visibility,
        }),
      };
    },
    initialData,
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const stableFiles = useMemo(() => {
    if (!filesQuery.data?.files) return [];

    return filesQuery.data.files.map((file) => ({
      ...file,
      _version: Date.now() + Math.random(),
    }));
  }, [filesQuery.data?.files]);

  const untaggedFileIds = useMemo(() => {
    if (!filesQuery.data?.files) return [];

    return filesQuery.data.files
      .filter((file) => file.tags.length === 0)
      .map((file) => file.id);
  }, [filesQuery.data?.files]);

  const tagCheckQuery = useQuery({
    queryKey: [
      'tag-check-query',
      scope_id,
      scope_type,
      untaggedFileIds.join(','),
    ],
    queryFn: async () => {
      if (untaggedFileIds.length === 0) {
        return { files: [] };
      }

      setIsRefetching(untaggedFileIds.length > 0);

      const response = await listFiles({
        query: {
          scope_id,
          scope_type,
          ownerId: claim?.owner?.id,
        },
      });

      return {
        files: response.files.filter((file) =>
          untaggedFileIds.includes(file.id),
        ),
      };
    },
    enabled: untaggedFileIds.length > 0,
    refetchInterval: untaggedFileIds.length > 0 ? 3000 : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 2000,
    onSuccess: (data) => {
      if (data?.files && data.files.length > 0) {
        const taggedFiles = data.files.filter((file) => file.tags.length > 0);

        if (taggedFiles.length > 0) {
          if (
            isRefetching &&
            untaggedFileIds.length === taggedFiles.length &&
            filesQuery.data?.files?.length > 0
          ) {
            setIsRefetching(false);
            queryClient.invalidateQueries({
              queryKey: [DOCUMENTATION_FILES_QUERY_KEY],
            });
            queryClient.invalidateQueries({
              queryKey: [SINGLE_FILE_QUERY_KEY],
            });
          }
        }
      }
    },
  });

  const { data: pendingFileRequest } = useQuery({
    queryKey: [buildClaimPendingFileRequestQueryKey(scope_id)],
    queryFn: () =>
      listPendingFileRequest({
        relationType: 'claim',
        relationTypeId: scope_id,
      }),
    refetchOnMount: true,
    initialData: initialPendingFileRequests,
    enabled: !!initialPendingFileRequests,
    refetchOnWindowFocus: false,
  });

  const debouncedGlobalFilter = useCallback(
    debounce((ev: React.ChangeEvent<HTMLInputElement>) => {
      setGlobalFilter(ev.target.value);

      if (onSearch) {
        onSearch(ev.target.value);
      }
    }, 500),
    [],
  );

  const FileListMemoized = useMemo(
    () => (
      <FileCardList>
        {stableFiles.map((file) => (
          <MemoizedFileCard
            key={file.id}
            file={file}
            allFiles={stableFiles}
            onOpenFile={onOpenFile}
          />
        ))}

        {noTagPlaceholder && tokenizeFile && (
          <UploadFileCustom
            tokenizeFile={(input) => tokenizeFile(input)}
            classNames={{
              input: 'h-[12.8rem] border-slate-300',
            }}
            addFiles={addUntaggedFiles}
          />
        )}
      </FileCardList>
    ),
    [
      stableFiles,
      noTagPlaceholder,
      tokenizeFile,
      addUntaggedFiles,
      onOpenFile,
      searchTags,
    ],
  );

  return (
    <div className='flex flex-col'>
      <div
        className={cn(
          'flex flex-row items-start justify-between space-x-2 w-full',
          {
            'mb-6': !initialPendingFileRequests,
          },
        )}
      >
        {title && (
          <div className='flex items-center justify-start h-8'>
            <h1 className='text-piramid-text text-sm font-medium'>{title}</h1>
          </div>
        )}

        <div className='flex flex-col items-end justify-end space-y-3'>
          {!hideSearch && (
            <div className='flex flex-row items-center space-x-2'>
              <Input
                onChange={debouncedGlobalFilter}
                placeholder='Buscar'
                className='h-8'
                defaultValue={globalFilter}
              />
              <DocumentTagsFilter
                searchTags={searchTags}
                setSelectedValues={(values) => {
                  setSelectedTags(values);

                  if (onFilterChange) {
                    onFilterChange({
                      selectedTags: values,
                    });
                  }
                }}
                selectedValues={selectedTags}
              />
            </div>
          )}
          {withFileRequestButton && userType !== 'inhouse_lawyer' && (
            <Link
              href={`/sinister/${claim?.sinister_id}/claim/${claim?.id}/documents/request-documents`}
              className={
                claim?.system_status === 'closed' ? 'pointer-events-none' : ''
              }
            >
              <Button
                type='button'
                className='h-8 truncate px-[.625rem] py-2 !text-base_brand rounded-sm'
                disabled={claim?.system_status === 'closed'}
              >
                Solicitar documentación
              </Button>
            </Link>
          )}
        </div>
      </div>

      {initialPendingFileRequests?.exists && userType !== 'inhouse_lawyer' && (
        <div
          className={cn('flex flex-wrap md:flex-row gap-2 mb-6', {
            'mt-6':
              pendingFileRequest?.exists &&
              pendingFileRequest.file_requests?.length,
          })}
        >
          {(() => {
            const seenTags = new Map<string, boolean>();

            return pendingFileRequest &&
              pendingFileRequest.exists &&
              pendingFileRequest.file_requests
              ? pendingFileRequest.file_requests.flatMap(
                  (fileRequest: any, requestIndex: number) =>
                    fileRequest.placeholders
                      // Filter out placeholders with tags we've already seen
                      .filter((placeholder: any) => {
                        if (seenTags.has(placeholder.tag_id)) {
                          return false;
                        }
                        seenTags.set(placeholder.tag_id, true);
                        return true;
                      })
                      .map((placeholder: any, placeholderIndex: number) => {
                        return (
                          <div
                            className={cn(
                              'cursor-default flex items-center justify-center rounded-xl text-base_brand border border-primary py-1 px-2 h-6',
                            )}
                            key={`${requestIndex}-${placeholderIndex}`}
                          >
                            <span className='text-base_brand text-piramid-text font-medium'>
                              {placeholder.tag?.name}
                            </span>
                          </div>
                        );
                      }),
                )
              : [];
          })()}
        </div>
      )}
      {initialPendingFileRequests?.exists && userType === 'inhouse_lawyer' && (
        <FileRequestMessage
          className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 space-y-0 mb-6'
          fileRequests={
            pendingFileRequest &&
            pendingFileRequest.exists &&
            pendingFileRequest.file_requests
              ? (pendingFileRequest.file_requests as FileRequestWithRelations[])
              : ([] as FileRequestWithRelations[])
          }
        />
      )}

      {filesQuery.data?.files?.length === 0 && (
        <DocumentsEmptyState
          initialPendingFileRequests={initialPendingFileRequests}
          tokenizeFile={tokenizeFile}
          addUntaggedFiles={addUntaggedFiles}
          userType={userType}
          withPlaceholderEmptyState={withPlaceholderEmptyState}
        />
      )}

      {filesQuery.data?.files?.length > 0 && FileListMemoized}
    </div>
  );
};
